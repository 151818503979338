import { getApolloClient } from '@confluence/graphql';

import { StarredSpacesQuery } from './SpacesListQueries.graphql';
import { SpacesListSpaceFragment } from './SpacesListSpaceFragment.fragment';
import type { SpacesListSpaceFragment as SpaceFragmentData } from './__types__/SpacesListSpaceFragment';
import type { StarredSpacesQuery as StarredSpacesQueryData } from './__types__/StarredSpacesQuery';

export const updateStarredSpacesList = (spaceId: string, isStarred: boolean) => {
	const apolloClient = getApolloClient();

	const space = apolloClient.readFragment<SpaceFragmentData>({
		id: `Space:${spaceId}`,
		fragment: SpacesListSpaceFragment,
		variables: {},
	});

	try {
		const starredSpaces =
			apolloClient.readQuery<StarredSpacesQueryData>({
				query: StarredSpacesQuery,
				variables: {},
			})?.spaces?.nodes || [];

		let newStarredSpaces;
		if (isStarred) {
			newStarredSpaces = [...starredSpaces, space];
			newStarredSpaces.sort((a, b) => (!a?.name || !b?.name ? 0 : a.name.localeCompare(b.name)));
		} else {
			newStarredSpaces = starredSpaces.filter((s) => s?.id !== spaceId);
		}

		apolloClient.writeQuery({
			query: StarredSpacesQuery,
			variables: {},
			data: {
				spaces: { nodes: newStarredSpaces, __typename: 'PaginatedSpaceList' },
			},
		});
	} catch {
		// If Apollo throws an error, the starred spaces data was not found in the cache.
		// Errors can be safely ignored as if there is no cache entry, there is no work to be done.
	}
};
