import React from 'react';

import StarredIcon from '@atlaskit/icon/core/star-starred';
import UnstarredIcon from '@atlaskit/icon/core/star-unstarred';
import { token, useThemeObserver } from '@atlaskit/tokens';

type IconSpacing = 'none' | 'spacious';

type StarIconProps = {
	isStarred: boolean;
	spacing?: IconSpacing;
};

export const StarIcon = ({ isStarred, spacing = 'spacious' }: StarIconProps) => {
	const theme = useThemeObserver();
	const starredColor =
		theme.colorMode === 'dark'
			? token('color.icon.accent.yellow')
			: token('color.icon.accent.orange');

	return isStarred ? (
		<StarredIcon label="" color={starredColor} spacing={spacing} />
	) : (
		<UnstarredIcon label="" color="currentColor" spacing={spacing} />
	);
};
