/**
 * @graphql cc
 */
import gql from 'graphql-tag';
import type { FC } from 'react';
import React, { useCallback, Fragment } from 'react';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import type { WrappedComponentProps } from 'react-intl';
import { defineMessages, injectIntl } from 'react-intl';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import type { ShortcutEvent } from '@confluence/shortcuts';
import { updateStarredSpacesList } from '@confluence/space-utils/entry-points/updateStarredSpacesList';
import { withFlags } from '@confluence/flags';
import type { WithFlagsProps } from '@confluence/flags';
import { fg } from '@confluence/feature-gating';

import { ActionErrorFlag } from '../SharedComponents';
import type { ButtonChildren } from '../PageStar/PageStar';
import { SpaceStarFragment } from '../ActionButtonFragments.fragment';

import type {
	SpaceStarUnfavoriteSpaceMutation as SpaceStarUnfavoriteSpaceMutation$data,
	SpaceStarUnfavoriteSpaceMutationVariables as SpaceStarUnfavoriteSpaceMutation$variables,
} from './__types__/SpaceStarUnfavoriteSpaceMutation';
import type {
	SpaceStarFavoriteSpaceMutation as SpaceStarFavoriteSpaceMutation$data,
	SpaceStarFavoriteSpaceMutationVariables as SpaceStarFavoriteSpaceMutation$variables,
} from './__types__/SpaceStarFavoriteSpaceMutation';
export type AnalyticsOptions = {
	source: string;
	containerId?: string;
	attributes?: Record<string, any>;
};

export type StarMutationArgs = {
	[attribute: string]: any;
};

type IconSpacing = 'none' | 'spacious';

export type SpaceStarProps = {
	spaceId: string;
	spaceKey: string;
	isStarred: boolean;
	onStar?: (e: MouseEvent | KeyboardEvent | ShortcutEvent) => void;
	onUnstar?: (e: MouseEvent | KeyboardEvent | ShortcutEvent) => void;
	analytics?: AnalyticsOptions;
	spacing?: IconSpacing;
	spaceName?: string;
	shouldShowFlags?: boolean;
};

const i18n = defineMessages({
	starErrorTitle: {
		id: 'action-buttons.space.star.error.title',
		// TODO: replace straight quotes with curly quotes (see go/curlyquotes)
		// eslint-disable-next-line no-restricted-syntax
		defaultMessage: "We're having trouble starring this space",
		description:
			"Title of error flag shown when a user attempts to click the 'Star this space' button but the starring action fails",
	},
	unstarErrorTitle: {
		id: 'action-buttons.space.unstar.error.title',
		// TODO: replace straight quotes with curly quotes (see go/curlyquotes)
		// eslint-disable-next-line no-restricted-syntax
		defaultMessage: "We're having trouble unstarring this space",
		description:
			"Title of error flag shown when a user attempts to click the 'Unstar this space' button but the unstarring action fails",
	},
	errorDescription: {
		id: 'action-buttons.error.description',
		defaultMessage: 'Refresh and try again.',
		description:
			'Body of error flag shown when user attempts an action (e.g. star) but it fails. Shown below the error title.',
	},
	starSuccessFlagTitle: {
		id: 'action-buttons.space.star.success.title',
		defaultMessage: '{spaceName} starred',
		description:
			"Title of success flag shown when a user clicks the 'Star' button for a space and the action is successful",
	},
	unstarSuccessFlagTitle: {
		id: 'action-buttons.space.unstar.success.title',
		defaultMessage: '{spaceName} unstarred',
		description:
			"Title of success flag shown when a user clicks the 'Unstar' button for a space and the action is successful",
	},
});
const SpaceStarComponent: FC<
	SpaceStarProps & ButtonChildren & WrappedComponentProps & WithFlagsProps
> = ({
	spaceId,
	spaceKey,
	isStarred,
	onStar,
	onUnstar,
	analytics,
	intl,
	children,
	flags,
	spaceName,
	shouldShowFlags,
}) => {
	const apolloClient = useApolloClient();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const modifyStarCache = useCallback(
		(newStarredValue: boolean) => {
			// The (Un)FavoriteSpaceMutations do not return the updated space, so we need to manually update the cache.
			// This is necessary to update the UI immediately after the user clicks the star button.
			apolloClient.writeFragment({
				id: `Space:${spaceId}`,
				fragment: SpaceStarFragment,
				data: {
					currentUser: {
						isFavourited: newStarredValue,
						__typename: 'SpaceUserMetadata',
					},
					__typename: 'Space',
				},
			});

			// Update the starred spaces list in the Spaces dropdown (and any other consumers of useSpacesData)
			updateStarredSpacesList(spaceId, newStarredValue);
		},
		[spaceId, apolloClient],
	);
	const [starSpace, { error: starError }] = useMutation<
		SpaceStarFavoriteSpaceMutation$data,
		SpaceStarFavoriteSpaceMutation$variables
	>(
		gql`
			mutation SpaceStarFavoriteSpaceMutation($spaceKey: String!) {
				favouriteSpace(spaceKey: $spaceKey) {
					isSpaceFavourited
				}
			}
		`,
		{
			onError: () => {
				// Revert the cache back to the previous state
				modifyStarCache(false);
			},
		},
	);
	const [unstarSpace, { error: unstarError }] = useMutation<
		SpaceStarUnfavoriteSpaceMutation$data,
		SpaceStarUnfavoriteSpaceMutation$variables
	>(
		gql`
			mutation SpaceStarUnfavoriteSpaceMutation($spaceKey: String!) {
				unfavouriteSpace(spaceKey: $spaceKey) {
					isSpaceFavourited
				}
			}
		`,
		{
			onError: () => {
				// Revert the cache back to the previous state
				modifyStarCache(true);
			},
		},
	);

	const toggleStar = useCallback(
		(e: any) => {
			e.preventDefault();
			if (isStarred) {
				onUnstar && onUnstar(e);
			} else {
				onStar && onStar(e);
			}

			// Optimistically update the cache
			modifyStarCache(!isStarred);

			const mutation = isStarred ? unstarSpace : starSpace;
			mutation({
				variables: { spaceKey },
			})
				.then(() => {
					if (isStarred && shouldShowFlags && fg('confluence_frontend_object_header')) {
						void flags.showSuccessFlag({
							title: intl.formatMessage(i18n.unstarSuccessFlagTitle, {
								spaceName,
							}),
							isAutoDismiss: true,
						});
					} else if (!isStarred && shouldShowFlags && fg('confluence_frontend_object_header')) {
						void flags.showSuccessFlag({
							title: intl.formatMessage(i18n.starSuccessFlagTitle, {
								spaceName,
							}),
							isAutoDismiss: true,
						});
					}
				})
				.catch((_) => {});

			if (analytics) {
				const { source, containerId, attributes } = analytics;
				createAnalyticsEvent({
					type: 'sendTrackEvent',
					data: {
						action: isStarred ? 'unstarred' : 'starred',
						actionSubject: 'space',
						source,
						attributes,
						...(containerId && { containerId }),
					},
				}).fire();
			}
		},
		[
			isStarred,
			spaceKey,
			analytics,
			starSpace,
			unstarSpace,
			modifyStarCache,
			onStar,
			onUnstar,
			createAnalyticsEvent,
			flags,
			intl,
			spaceName,
			shouldShowFlags,
		],
	);

	return (
		<Fragment>
			{children({
				toggle: toggleStar,
			})}
			{starError && (
				<ActionErrorFlag
					title={intl.formatMessage(i18n.starErrorTitle)}
					description={intl.formatMessage(i18n.errorDescription)}
					error={starError}
				/>
			)}
			{unstarError && (
				<ActionErrorFlag
					title={intl.formatMessage(i18n.unstarErrorTitle)}
					description={intl.formatMessage(i18n.errorDescription)}
					error={unstarError}
				/>
			)}
		</Fragment>
	);
};

export const SpaceStar = withFlags(injectIntl(SpaceStarComponent));
