import type { FC } from 'react';
import React from 'react';
import { useIntl } from 'react-intl-next';

import Tooltip from '@atlaskit/tooltip/Tooltip';
import Button from '@atlaskit/button/standard-button';

import {
	GeneralShortcutListener,
	ShortcutVisualizer,
	STAR_BUTTON_SHORTCUT,
} from '@confluence/shortcuts';

import { HoverButton, HiddenAction } from '../PresentationalComponents';
import type { ButtonProps } from '../PageStar/PageStarButton';
import { StarIcon } from '../StarIcon';

import { spaceStarI18n } from './SpaceStarI18n';
import type { SpaceStarProps } from './SpaceStar';
import { SpaceStar } from './SpaceStar';

/**
 * Icon button that allows users to star or unstar a space.
 *
 * Renders as a star icon. Typically used in lists and tables (e.g. Spaces menu, Space directory).
 */
export const SpaceStarButton: FC<ButtonProps & SpaceStarProps> = ({
	size = 'medium',
	outlineAppearance = 'subtle',
	tooltipPosition = 'bottom',
	shortcut,
	label,
	...props
}) => {
	const intl = useIntl();
	const spacing = size === 'small' ? 'compact' : 'default';
	const buttonComponent = outlineAppearance === 'subtle' ? HoverButton : undefined;
	const tooltipLabel = props.isStarred
		? intl.formatMessage(spaceStarI18n.unstar)
		: intl.formatMessage(spaceStarI18n.star);
	const tooltipContent = shortcut ? (
		<ShortcutVisualizer shortcut={shortcut} contentBefore={tooltipLabel} />
	) : (
		tooltipLabel
	);
	const buttonLabel = label ?? intl.formatMessage(spaceStarI18n.star);

	return (
		<SpaceStar {...props}>
			{({ toggle: toggleStar }) => (
				<>
					<Tooltip
						content={tooltipContent}
						position={tooltipPosition}
						hideTooltipOnClick
						hideTooltipOnMouseDown
					>
						{props.isStarred ? (
							<Button
								id={`star-button-${props.spaceKey}`}
								onClick={toggleStar}
								spacing={spacing}
								appearance="subtle"
								iconBefore={<StarIcon isStarred spacing="spacious" />}
								aria-label={buttonLabel}
								aria-labelledby={`star-button-${props.spaceKey} space-link-${props.spaceKey}`}
								aria-pressed
								testId="star-button-starred"
							/>
						) : (
							<HiddenAction>
								<Button
									id={`star-button-${props.spaceKey}`}
									onClick={toggleStar}
									spacing={spacing}
									appearance="subtle"
									iconBefore={<StarIcon isStarred={false} spacing="spacious" />}
									component={buttonComponent}
									aria-label={buttonLabel}
									aria-labelledby={`star-button-${props.spaceKey} space-link-${props.spaceKey}`}
									aria-pressed={false}
									testId="star-button-unstarred"
								/>
							</HiddenAction>
						)}
					</Tooltip>
					{shortcut && (
						<GeneralShortcutListener accelerator={STAR_BUTTON_SHORTCUT} listener={toggleStar} />
					)}
				</>
			)}
		</SpaceStar>
	);
};
